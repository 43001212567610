$(document).ready(function($) {

	var $app = $('html') 
	var $appContent = $('#page_content')
	var debug = true; if(typeof MH_BASIS_DEBUG != 'undefined') debug = true
	
	$app 
		.bind('init_app', function(e){
		
			if(debug) console.log("\n $app > " + e.type)	

			$app.trigger('init_fastClick')
			$app.trigger('init_something')
			$app.trigger('app_ready')
		
		})
		.bind('init_fastClick', function(e){
		
			if(debug) console.log("\n $app > " + e.type)		
			
			// still needed for iOS 8
			// https://github.com/ftlabs/fastclick			
			if (FastClick) {
				FastClick.attach(document.body);
			}
			
		})
		.bind('init_something', function(e){
		
			if(debug) console.log("\n $app > " + e.type)
			
		})
		.bind('app_ready', function(e){
		
			if(debug) console.log("\n $app > " + e.type)
			
			$app
				.addClass('app_ready')
			
		})
		//.trigger('init_app')
	
}); 
